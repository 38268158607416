$content-size: 1110px;

$primary: #202072;
$primary-dark: #101062;
$primary-light: #303082;

$secondary: #0AC9B0;
$secondary-dark: #2B918A;

$background-section: #eeffee;

$text-color: #f0f8ff;
$text-color-black: black;

$gray: #CDCDCD;

$mobile: 1100px;