@import '../../variables';

.layoutContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px 0px;
    box-sizing: border-box;

    @media screen and (max-width: $mobile) {
        height: auto;
    }

    .layoutContent {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        width: 1100px;

        background-color: white;
        border: 2px solid #c29011;
        border-radius: 12px;

        padding: 0px 32px;
    
        @media screen and (max-width: $mobile) {
            width: 90%;
        }
    
        > p {
            margin-top: 0;
        }

        > ul {
            margin-top: 0;
        }

        >img {
            align-self: center;
            width: fit-content;
            max-height: 300px;
            margin-bottom: 16px;

            @media screen and (max-width: $mobile) {
                max-height: inherit;
                max-width: 95%;
            }
        }
    }
}
