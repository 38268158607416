@import '../../variables';

.aboutMeContainer {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $mobile) {
        flex-direction: column;
    }

    >.left {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        padding: 30px 0px;

        >img {
            width: 300px;
            border-radius: 100%;
        }

        @media screen and (max-width: $mobile) {
            padding: 30px 0px 24px;
            >img {
                width: 200px;
            }
        }
    }

    >.right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        padding: 30px 0px;

        @media screen and (max-width: $mobile) {
            padding: 0px 0px 16px;
        }

        >p {
            margin: 0px;
        }

        >.channels {
            display: flex;
            justify-content: space-around;
            padding: 24px 0px;

            @media screen and (max-width: $mobile) {
                justify-content: space-between;
            }
    
            > a {
                width: 48px;
    
                @media screen and (max-width: $mobile) {
                    width: 36px;
                }
    
                > img {
                    width: 100%;
                }
            }
        }
    }
}