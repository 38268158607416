@import '../../variables';

.aboutMeTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 42px;

    >.logo {
        width: 50%;
    }

    @media screen and (max-width: $mobile) {
        justify-content: center;
        margin-bottom: 12px;

        >img {
            width: 80%;
        }

        >.logo {
            width: 80%;
            margin-bottom: 16px;
        }
    }
}

.kellerTest {
    color: $primary;
    font-weight: 700;

    // @media screen and (max-width: $mobile) {
        
    // }
}

.buttonContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;

    @media screen and (max-width: $mobile) {
        flex-direction: column;
        
        >:first-child {
            margin-bottom: 12px;
        }
    }


    >.button {
        border: 4px solid $primary;
        border-radius: 8px;
        padding: 16px;
        background-color: transparent;
        transition: all ease 0.5s;
        cursor: pointer;
        display: flex;
        align-items: center;

        @media screen and (max-width: $mobile) {
            padding: 8px 12px;
        }

        >.icon {
            width: 36px;
            margin-right: 8px;

            >svg {
                width: 100%;
                height: auto;
                fill: $primary;
            }
        }
        >.text {
            color: $primary;
            font-weight: 700;
        }

        &:hover {
            background-color: $primary;
            >.text {
                color: white;
            }
            >.icon {
                >svg {
                    fill:white
                }
            }
        }
    }
}

a {
    color: $primary-light;
    text-decoration: none;
    user-select: none;
    display: inline; /* should be 'inline' for multiple line animation */
    background-image: linear-gradient(to right, black, $primary);
    background-size: 0% 3px;
    background-repeat: no-repeat;
    transition: background-size 0.3s;
    background-position: 0 calc(100% - 0px);

    &:hover {
        background-size: 100% 3px;
    }

    &.bold {
        font-weight: 700;
    }
}